<template>
  <div>
    <Transition name="slide-fade-x">
      <div v-if="selected.length > 0" class="d-flex justify-content-between gap-3 me-3">
        <slot name="functions" :rows="selected"></slot>
        <b-button @click="generateExcel()" variant="outline-primary"
                  v-b-tooltip.hover title="Download Excel"
                  class="btn-icon waves-effect"
        >
          <font-awesome-icon icon="fa-solid fa-file-excel" class="fs-5"/>
        </b-button>
      </div>
    </Transition>
  </div>
  <b-button
      v-if="excel_url"
      @click="exportAllData()"
      variant="outline-primary" v-b-tooltip.hover title="Export All Data"
      class="waves-effect me-3">
    <font-awesome-icon icon="fa-solid fa-file-excel" class="fs-5 align-middle me-1"/>
    <span class="fw-medium">
           {{ is_exporting_all_data ? 'Exporting ...' : ' Export All' }}
          </span>
  </b-button>
</template>

<script>
import {saveAs} from 'file-saver';
import ExcelJS from 'exceljs';
import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: {
    table_name: {
      type: String,
      required: false,
      default: () => 'Table',
    },
    table_headers: {
      type: Array,
      required: true,
    },
    table_data: {
      type: Array,
      required: true,
    },
    selected_data: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: () => '',
    },
    excel_url: {
      type: String || null,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      is_exporting_all_data: false
    }
  },
  computed: {
    headers() {
      return (this.table_headers || []).filter(header => header.field !== "actions")
    },
    data() {
      return this.table_data || []
    },
    selected() {
      return this.selected_data || []
    },
  },
  methods: {
    async generateExcel() {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        let bookName = this.table_name.toString().toLowerCase()
            .replace(/\s+/g, '-')      // Replace spaces with -
            .replace(/[^\w-]+/g, '')   // Remove all non-word chars excluding the hyphen
            .replace(/--+/g, '-')      // Replace multiple - with single -
            .replace(/^-+/, '')        // Trim - from start of text
            .replace(/-+$/, '');

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(bookName);

        const headers = this.headers.filter(header => header.visible !== false);

        const columnWidths = {};

        worksheet.columns = headers.map(header => {
          const column = {header: header.label, key: header.field};

          this.selected.forEach(d => {
            const value = header.keys && header.keys.length > 0
                ? header.keys.reduce((obj, key) => (obj ? obj[key] : ''), d[header.field])
                : d[header.field];

            const stringValue = value !== null && value !== undefined ? String(value) : '';
            const stringLength = stringValue.length;
            columnWidths[header.field] = Math.max(
                columnWidths[header.field] || 0,
                stringLength
            );
          });

          return column;
        });

        headers.forEach(header => {
          const column = worksheet.getColumn(header.field);
          column.width = Math.min(50, Math.max(10, columnWidths[header.field] * 1.5)); // Adjust the multiplication factor as needed
        });

        this.selected.forEach(d => {
          const rowData = {};
          headers.forEach(header => {
            if (header.excel_data) {
              rowData[header.field] = header.excel_data(d);
            } else {
              rowData[header.field] = d[header.field];
            }
          });
          worksheet.addRow(rowData);
        });


        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
          cell.fill = {
            bgColor: {argb: 'ffffff00'}, // Set background color to yellow
          };
          cell.font = {
            bold: true,
            color: {argb: '000000'}, // Set font color to white,
          };
          cell.alignment = {vertical: 'middle', horizontal: 'center'};
        });

        worksheet.eachRow({includeEmpty: true}, function (row) {
          row.eachCell({includeEmpty: true}, function (cell) {
            cell.alignment = {vertical: 'middle', horizontal: 'center'};
            cell.border = {
              top: {style: 'thin'},
              left: {style: 'thin'},
              bottom: {style: 'thin'},
              right: {style: 'thin'},
            };
          });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${bookName}.xlsx`);
        await Toast.fire({
          icon: 'success',
          title: 'Excel Downloaded Successfully'
        })
      } catch (e) {
        await Toast.fire({
          icon: 'error',
          title: 'Could not download excel, try again later'
        })
      }
    },
    async exportAllData() {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (!this.url || !this.excel_url) {
        await Toast.fire({
          icon: 'error',
          title: 'Cannot perform this action right now!'
        })
        return
      }
      this.is_exporting_all_data = true
      try {
        const downloadUrl = `${process.env.VUE_APP_ORDER_URL}${this.url}${this.excel_url}`;
        let axios_params = new Object({});

        Object.entries(this.$route.query || {}).forEach(([key, value]) => {
          axios_params[key] = value;
        });

        const response = await axios.get(downloadUrl, {
          responseType: 'blob',
          params: axios_params
        });

        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const downloadLink = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = downloadLink;
        anchor.download = this.table_name || 'ExcelData.xlsx';
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(downloadLink);
        this.is_exporting_all_data = false;
      } catch (error) {
        this.is_exporting_all_data = false;
        alert('An error occurred while downloading the file. Sorry.');
        console.error(error);
      }
    }
  }
}

</script>


<style scoped>
/* TRANSITION FADE X */

.slide-fade-x-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-x-leave-active {
  transition: all 0.1s cubic-bezier(1, 1, 0.8, 1);
}

.slide-fade-x-enter-from,
.slide-fade-x-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>