<script>
import Swal from "sweetalert2";
import axios from "axios";
import CardHeader from "@/components/tables/components/CardHeader.vue";
import THeader from "@/components/tables/components/THeader.vue";
import TBody from "@/components/tables/components/TBody.vue";


export default {
  name: "CustomTable2",
  emits: ['onRowSelect', 'onRowClick', 'onLoaded'],
  data() {
    return {
      num_of_calls: 0,
      date: null,
      searchTimeout: 1000,

      config: {
        mode: "single",
      },

      modals: {
        headersController: false,
      },

      table: {
        data: [],
        selected: [],
        per_page_options: [10, 20, 50, 100],
        per_page: this.perPage,
        current_page: 1,
        data_count: 0,
        mounted: false,
      },

      apiData: [],
      selected_data: [],
      isFetchingData: false,
      isLoadingMore: false,

      localHeaders: []
    };
  },

  props: {
    name: {
      type: String,
      default: () => "Table",
      required: false
    },
    id: {
      type: String,
      default: () => "table",
      required: false
    },
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    url: {
      type: String,
      default: () => "",
      required: true
    },
    excel_url: {
      type: String,
      required: false,
    },
    searchable: {
      type: Boolean,
      default: () => false,
      required: false
    },
    selectable: {
      type: Boolean,
      default: () => false,
      required: false
    },
    perPage: {
      type: Number,
      default: () => 10,
      required: false
    },
    getUpdate: {
      type: Boolean,
      default: () => false,
      required: false
    },
    per_page_location: {
      type: String,
      default: () => "top",
      required: false
    },
    infiniteScroll: {
      type: Boolean,
      default: () => false,
      required: false
    },
    hoverable: {
      type: Boolean,
      default: () => true,
      required: false
    },
    indexed: {
      type: Boolean,
      default: () => false,
      required: false
    },
    enable_global_search: {
      type: Boolean,
      default: () => false,
      required: false
    },
  },
  components: {
    CardHeader,
    THeader,
    TBody,
  },
  computed: {
    getHeaders() {
      return this.localHeaders.map(header => ({
        ...header,
      }));
    },
    storageKey() {
      return `table__${this.slugify(this.name)}__headers`;
    },
    pagination: {
      get() {
        return {
          current_page: this.table.current_page,
          count: this.table.data_count,
          per_page: this.table.per_page || 10,
          ratio: Math.ceil(this.table.data_count / (this.infiniteScroll ? this.apiData.length : this.table.per_page)),
        };
      },
    },
    selected_data_computed: {
      get() {
        return this.selected_data;
      },
    },
    showing_from: {
      get() {
        return this.pagination.per_page * (this.pagination.current_page - 1) === 0
            ? 1
            : this.pagination.per_page * (this.pagination.current_page - 1) + 1
      }
    },
    showing_to: {
      get() {
        return this.infiniteScroll ? this.apiData.length : this.pagination.per_page * this.pagination.current_page >
        this.pagination.count
            ? this.pagination.count
            : this.pagination.per_page * this.pagination.current_page
      }
    },
    all_rows_are_selected() {
      return this.selected_data.length === this.apiData.length
    },
    is_searching_globally() {
      if (!this.enable_global_search) return false
      return (this.$route.query.search || '').length > 0
    }
  },
  methods: {
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();

      var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
      var to = 'aaaaeeeeiiiioooouuuunc------';

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
          .replace(/\s+/g, '-')
          .replace(/-+/g, '-');

      return str;
    },

    loadHeadersFromLocalStorage() {
      const storedHeaders = JSON.parse(localStorage.getItem(this.storageKey));
      if (storedHeaders && storedHeaders.length > 0) {
        this.localHeaders = this.headers.filter(header =>
            storedHeaders.includes(header.field)
        );
      } else {
        this.localHeaders = this.headers.filter(
            header => header.visible === undefined || header.visible === true
        );
      }
      localStorage.setItem(this.storageKey, JSON.stringify(this.getHeaders.map(i => i.field)))
    },

    async getData(loadMore) {
      this.selected_data = []
      // Create a CancelToken source
      const cancelTokenSource = axios.CancelToken.source();

      // If a previous request is in progress, cancel it
      if (this.cancelSource) {
        this.cancelSource.cancel('Request canceled due to new request.');
      }

      // Save the current cancel token source
      this.cancelSource = cancelTokenSource;

      this.num_of_calls += 1;
      let axios_params = new Object({});

      const orderBy = this.$route.query.order_by;
      if (orderBy && orderBy !== "") {
        const orderByField = orderBy.startsWith('-') ? orderBy.split('-')[1] : orderBy;
        const headerFields = this.getHeaders.map(h => h.field);
        if (headerFields.includes(orderByField)) {
          axios_params["order_by"] = orderBy;
        }
      }

      Object.entries(this.$route.query || {}).forEach(([key, value]) => {
        if (this.getHeaders.map(h => h.field).includes(key) && !this.is_searching_globally) {
          axios_params[key] = value;
        }
      });

      axios_params["limit"] = this.pagination.per_page;
      axios_params["search"] = this.$route.query.search || undefined;

      if (loadMore) {
        this.selected_data = []
        this.table.current_page = 1
        axios_params["offset"] = this.apiData.length;
        this.isLoadingMore = true;
      } else {
        axios_params["offset"] = this.pagination.per_page * (this.pagination.current_page - 1);
        this.isFetchingData = true;
      }

      await axios
          .get(this.url, {
            params: axios_params,
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            let data = response.data;
            if (loadMore) {
              this.apiData = [...this.apiData, ...data["results"]];
            } else {
              this.apiData = data["results"] || [];
            }
            this.table.data_count = data["count"] || this.apiData.length;
            this.apiData.forEach((item, index) => {
              item["id"] = item["id"] || index;
            });
            this.isFetchingData = false;
            this.isLoadingMore = false;

            this.$emit('onLoaded', this.apiData)
          })
          .catch((error) => {

            if (axios.isCancel(error)) {
              console.log('Request canceled:', error.message);
            } else {

              this.isFetchingData = false;
              this.isLoadingMore = false;
              this.$emit('onLoaded', [])
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 30000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "error",
                title: "Something went wrong!",
              });
            }
          });
    },
    changeTablePerPage(page) {
      this.table.per_page = page;
      this.selected_data = []
      this.table.current_page = 1
    },
    async onSearchChange() {
      this.table.current_page = 1
      // await this.getData();
    },
    goNextPage() {
      if (
          this.pagination.ratio === 1 ||
          this.pagination.current_page === this.pagination.ratio
      )
        return;
      this.selected_data = []
      this.table.current_page = this.table.current_page + 1;
      this.getData();
    },
    goPrevPage() {
      if (this.pagination.ratio === 1 || this.pagination.current_page === 1)
        return;
      this.table.current_page = this.table.current_page - 1;
      this.getData();
    },
    goPage(page) {
      if (this.pagination.current_page === page) return;
      this.table.current_page = page;
      this.selected_data = []
      this.getData();
    },
    selectAll() {
      this.selected_data = this.all_rows_are_selected ? [] : [...this.apiData]
      this.$emit('onRowSelect', this.selected_data)
    },
    selectRows(selected_row, rows_selected_with_shift) {
      if (rows_selected_with_shift) {
        if (this.selected_data.includes(selected_row)) {
          this.selected_data = this.selected_data.filter((row) => row.id !== selected_row.id)
        } else {
          this.selected_data = rows_selected_with_shift || []
        }
      } else {
        this.selected_data.includes(selected_row)
            ? this.selected_data.splice(this.selected_data.indexOf(selected_row), 1)
            : this.selected_data.push(selected_row)
      }
      this.$emit('onRowSelect', this.selected_data)
    },
    rowClick(row) {
      this.$emit('onRowClick', row)
    },
    handlePageScroll() {
      if (this.infiniteScroll !== true) return;
      let table = this.$refs.tableContainer
      if (table.getBoundingClientRect().bottom < window.innerHeight) {
        if (this.isFetchingData || this.isLoadingMore
            || this.table.data_count === this.apiData.length
        ) return;
        this.getData(true, "handlePageScroll")
      }
    },
  },
  mounted() {
    this.table.mounted = true
    let per_page_from_local_storage = localStorage.getItem(`table__${this.slugify(this.name)}__per_page`)
    if (per_page_from_local_storage) {
      this.table.per_page = JSON.parse(per_page_from_local_storage) || 10;
    } else {
      this.table.per_page = this.perPage || 10;
    }
    if (this.infiniteScroll === true) {
      window.addEventListener("scroll", this.handlePageScroll)
    }
  },
  beforeUnmount() {
    if (this.infiniteScroll === true) {
      window.removeEventListener("scroll", this.handlePageScroll)
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        if (this.getHeaders.length === 0) {
          this.loadHeadersFromLocalStorage()
        }
        this.getData()
      },
    },
    "table.per_page": {
      handler() {
        this.selected_data = []
        this.table.current_page = 1
        this.getData(false, "me")
        localStorage.setItem(`table__${this.slugify(this.name)}__per_page`, JSON.stringify(this.table.per_page))
      },
      immediate: false
    },
    isFetchingData: {
      immediate: false,
      handler() {
        if (!this.isFetchingData && this.infiniteScroll === true) {
          setTimeout(() => {
            this.handlePageScroll()
          }, 200)
        }
      },
    },
  }
};
</script>

<template>
  <div ref="tableContainer" class="" id="interrail_table">
    <div class="card rounded-table-card mb-0">
      <CardHeader
          :table_name="name"
          :headers="headers"
          :table_data="apiData"
          :selected_data="selected_data_computed"
          :url="url" :excel_url="excel_url || null"
          :showing_from="showing_from"
          :showing_to="showing_to"
          :data_count="pagination.count"
          :enable_global_search="enable_global_search"
          :storageKey="storageKey"
          @header_change="loadHeadersFromLocalStorage()"
      >
        <template v-slot:functions="selected_data">
          <slot name="functions"
                :rows="selected_data.rows"
                :count="selected_data.rows.length"
          >
          </slot>
        </template>
        <template v-slot:top-right>
          <slot name="top-right"></slot>
        </template>
        <template v-slot:header_div>
          <slot name="header_div"></slot>
        </template>
      </CardHeader>

      <div class="card-body pt-0 mt-0">
        <div class="pt-3">
          <div class="table-responsive table-card border-bottom-0">
            <table :class="{'table-hover': hoverable}"
                   class="table align-middle table-nowrap" :id="id">

              <THeader
                  :is_loading="isFetchingData"
                  :selectable="selectable"
                  :searchable="searchable"
                  :headers="getHeaders"
                  :all_rows_are_selected="all_rows_are_selected"
                  @selectAll="selectAll"
                  :per_page="table.per_page"
                  :per_page_location="per_page_location"
                  @changePerPage="changeTablePerPage($event)"
                  @searchChange="onSearchChange($event)"
                  :indexed="indexed"
                  :enable_global_search="enable_global_search"
                  :is_searching_globally="is_searching_globally"
              />

              <TBody
                  :headers="getHeaders"
                  :table_data="apiData"
                  :is_loading="isFetchingData"
                  :selectable="selectable"
                  :selected_data="selected_data_computed"
                  @selectRow="selectRows"
                  @rowClick="rowClick"
                  :is_loading_more="isLoadingMore"
                  :indexed="indexed"
                  :showing_from="showing_from"
              >
              <template v-for="(_, name) in $slots" v-slot:[name]="{ row: item, index }">
                <slot :name="name" :row="item"
                      :index="index"/>
              </template>
              </TBody>

            </table>
          </div>
        </div>
      </div>


      <div class="card-footer rounded-table-card d-flex justify-content-between align-items-center pt-0 border-top-0"
           v-if="apiData.length > 0 && !isFetchingData">
        <div>
          <div class="btn-group" v-if="selectable && per_page_location === 'bottom'">
            <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false">
              {{ table.per_page }}
            </button>
            <div class="dropdown-menu dropdownmenu-info">
              <a class="dropdown-item" v-for="page in table.per_page_options" :key="page" :class="{
                            'text-info bg-soft-info fw-bold':
                              page === table.per_page,
                          }" @click="changeTablePerPage(page)">
                {{ page }}
              </a>
            </div>
          </div>
        </div>
        <div v-if="infiniteScroll !== true">
          <div v-if="pagination.ratio <= 6" class="d-flex justify-content-end py-0 w-100">
            <ul class="pagination pagination-sm pagination-separated my-0">
              <li class="page-item" @click="goPrevPage()" :class="{
                disabled:
                  pagination.ratio === 1 || pagination.current_page === 1,
              }">
                <a class="page-link cursor-pointer"> Prev </a>
              </li>

              <li v-for="page in pagination.ratio" :key="page" class="page-item"
                  :class="{ active: page === pagination.current_page }" @click="goPage(page)">
                <a class="page-link cursor-pointer">
                  {{ page }}
                </a>
              </li>

              <li class="page-item" @click="goNextPage()" :class="{
                disabled:
                  pagination.ratio === 1 ||
                  pagination.current_page === pagination.ratio,
              }">
                <a class="page-link cursor-pointer"> Next </a>
              </li>
            </ul>
          </div>
          <div v-else-if="pagination.ratio >= 7" class="d-flex justify-content-end py-0 w-100">
            <ul class="pagination pagination-sm pagination-separated my-0">
              <li class="page-item" @click="goPrevPage()" :class="{
                disabled:
                  pagination.ratio === 1 || pagination.current_page === 1,
              }">
                <a class="page-link cursor-pointer"> Prev </a>
              </li>

              <li v-for="page in pagination.ratio" :key="page" @click="goPage(page)" class="page-item"
                  :class="{ active: page === pagination.current_page }">
                <a class="page-link cursor-pointer" v-if="pagination.current_page - 2 <= page &&
                  pagination.current_page + 2 >= page
                  ">
                  {{ page }}
                </a>
              </li>

              <li class="page-item" @click="goNextPage()" :class="{
                disabled:
                  pagination.ratio === 1 ||
                  pagination.current_page === pagination.ratio,
              }">
                <a class="page-link cursor-pointer"> Next </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rounded-table-card {
  border-radius: 17px;
}

.table-description-animation-wrapper h6 {
  position: absolute;
  top: 25px;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>