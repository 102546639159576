<template>
  <PageHeader :title="title" :items="items"/>

  <div class="mb-4">
    <Table
        name="Companies List"
        :headers="table.header"
        url="/customer/companies/"
        :getUpdate="table.getUpdate"
        :searchable="table.searchable"
        :selectable="true"
    >

      <template v-slot:name="{row: company}">
      <span>
          {{ company.name }}
      </span>
      </template>

      <template #top-right>
        <a class="btn btn-success" href="#CreateClientModal" data-bs-toggle="modal"><i
            class="ri-add-line align-bottom me-1"></i>
          Create Company</a>
      </template>
      <template v-slot:actions="props">
        <div class="w-100 text-center" :id="props.row.id">
          <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
            <b-button variant="light" class="p-0" @click="current_company = props.row" href="#CreateUpdateUser"
                      data-bs-toggle="modal">
              <div class="ps-2">
                <font-awesome-icon
                    icon="fa-solid fa-user-plus"
                    class="fs-10 ms-1"
                />
              </div>
            </b-button>
            <b-button variant="light" @click="current_company = props.row"
                      data-bs-target="#company_delete_modal"
                      data-bs-toggle="modal">
              <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
            </b-button>
          </b-button-group>

        </div>
      </template>
    </Table>
  </div>

  <CreateUpdateUser :user="{company_id: current_company.id}"/>
  <CreateClientModal @client-created="table.getUpdate = !table.getUpdate"/>
  <DeleteClientModal @deleted="table.getUpdate = !table.getUpdate" :company="current_company"/>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import Table from "@/components/tables/table.vue";

import CreateClientModal from '@/views/pages/accounts/counterparties/modals/client_create.vue'
import DeleteClientModal from '@/views/pages/accounts/counterparties/modals/client_delete.vue'
import CreateUpdateUser from '@/views/pages/accounts/counterparties/modals/CreateUpdateUser.vue'

export default {
  name: "clients_list",
  components: {
    PageHeader,
    Table,
    CreateUpdateUser,
    CreateClientModal,
    DeleteClientModal
  },
  data() {
    return {
      title: "Users Management",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Clients",
          active: true,
        },
      ],
      table: {
        getUpdate: false,
        searchable: true,
        header: [
          {
            label: "Name",
            field: "name",
            align: "center",
          },
          {
            label: "Email",
            field: "email",
            align: "center",
          },
          {
            label: "Phone",
            field: "phone",
            align: "center",
          },
          {
            label: "Address",
            field: "address",
            align: "center",
          },
          {
            label: "Actions",
            field: "actions",
            align: "center",
          },
        ],
      },

      current_company: {},
    }
  }
}
</script>

<style scoped>

</style>