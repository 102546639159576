<template>
    <div class="modal fade" id="CreateUpdateUser" tabindex="-1" role="dialog"
         aria-labelledby="CreateUpdateUser"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="CreateUpdateUser">{{ user_.id ? 'Update' : 'Create' }} User Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="upsertUser()">
                        <div class="mb-3">
                            <label for="First Name" class="form-label text-dark">First Name</label>
                            <input v-model="user_.first_name" type="text" class="form-control"
                                   placeholder="Enter first name">
                        </div>
                        <div class="mb-3">
                            <label for="Last Name" class="form-label text-dark">Last Name</label>
                            <input v-model="user_.last_name" type="text" class="form-control"
                                   placeholder="Enter last name">
                        </div>
                        <div class="mb-3">
                            <label for="Email" class="form-label text-dark">Email</label>
                            <input v-model="user_.email" type="email" class="form-control"
                                   placeholder="Enter email">
                        </div>
                        <div class="mb-3">
                            <label for="Password" class="form-label text-dark">Password</label>
                            <input v-model="user_.password" type="password" class="form-control"
                                   placeholder="Enter password">
                        </div>
                        <div class="mb-3">
                            <label for="Roles" class="form-label text-dark">Roles</label>
                            <Multiselect :options="role.options"
                                         v-model="role.selected"
                                         :object="true"
                                         @search-change="getOptions($event)"
                                         :searchable="true"
                                         placeholder="Select Role"/>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-success" data-bs-dismiss="modal">
                                {{ user_.id ? 'Update' : 'Create' }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
    emits: ['update'],
    name: "CreateUpdateUser",
    props: {
        c: {
            type: Object,
            required: true,
        },
    },
    components: {
        Multiselect
    },
    data() {
        return {
            user_: {},
            role: {
                selected: null,
                options: []
            }
        }
    },
    watch: {
        user(value) {
            this.user_ = value
        }
    },
    methods: {
        async getOptions(query) {
            if (query.length <= 1) return;
            let response = await axios.get(`/user/roles/`);
            this.roles = response.data.results;
            this.role.selected = null
            this.role.options = this.roles.map((role) => {
                return {
                    value: role.id,
                    label: role.name,
                }
            })

        },
        async upsertUser() {
            try {
                const body = this.user_
                body.company_id = this.user_.company_id ? this.user_.company_id : null
                body.roles = this.role.selected ? [{id: this.role.selected.value}] : []
                if (this.user.id) {
                    await axios.put('/user/' + this.user.id + '/update/', body)
                } else {
                    await axios.post('/user/create/', body)
                }
                this.$emit('update')
                await Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `User ${this.user.id ? 'updated' : 'created'} successfully!`,
                })
            } catch (e) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    html: Object.values(e.response.data).map((item) => {
                        return `<p>${item}</p>\n`
                    }).join(''),
                })
            }
        }
    },
}
</script>

<style scoped>

</style>