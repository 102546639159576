<script>
export default {
  name: "Statistics",
  data() {
    return {
      showModal: false,
    }
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
  }
}
</script>

<template>
<!--  <b-button-->
<!--      v-b-tooltip.hover title="Table Statistics"-->
<!--      variant="outline-primary"-->
<!--      class="btn-icon waves-effect me-3"-->
<!--      @click="showModal = true"-->
<!--  >-->
<!--    <i class="ri-bar-chart-fill"></i>-->
<!--  </b-button>-->

  <b-modal v-model="showModal" size="xl" hide-footer title="Table Statistics" class="v-modal-custom" centered>
    Hello
  </b-modal>
</template>

<style scoped>

</style>