<script>
export default {
  name: "PerPageDropdown",
  emits: ['changePerPage'],
  props: {
    per_page: {
      type: Number,
      required: true,
      default: () => 10
    }
  },
  computed: {
    table_per_page: {
      get() {
        return this.per_page
      },
      set(value) {
        this.$emit('changePerPage', value)
      }
    }
  }
}
</script>

<template>
  <div class="btn-group">
    <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
      {{ table_per_page }}
    </button>
    <div class="dropdown-menu dropdownmenu-info">
      <a class="dropdown-item" v-for="page in [10, 25, 50, 100]" :key="page"
         :class="{
                     'text-info bg-soft-info fw-bold':
                     page === table_per_page,
             }"
         @click="table_per_page = page"
      >
        {{ page }}
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>