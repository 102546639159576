<template>
  <div class="py-4 text-center">
    <lottie
      colors="primary:#405189,secondary:#08a88a"
      :options="defaultOptions"
      :height="80"
      :width="80"
    />
    <h5 class="mb-0 mt-3">
      <slot>No data here!</slot>
    </h5>
  </div>
</template>

<script>
import spxnqpau from "@/components/widgets/spxnqpau.json";
import Lottie from "../widgets/lottie.vue";

export default {
  data() {
    return {
      defaultOptions: { animationData: spxnqpau },
    };
  },
  components: {
    lottie: Lottie,
  },
};
</script>
