<template>
  <template v-if="show_data_after_loading">
    <template v-if="loading">
      <h2 class="skeleton bg-soft-dark my-0" :class="class_list" v-if="custom_style.length > 0"
          :style="custom_style"></h2>
      <h2 class="skeleton bg-soft-dark my-0" :class="class_list" v-else style="min-height: 10px"></h2>
    </template>
    <template v-else>
      <slot/>
    </template>
  </template>
  <template v-else>
    <h2 class="skeleton bg-soft-dark my-0" :class="class_list" v-if="custom_style.length > 0"
        :style="custom_style"></h2>
    <h2 class="skeleton bg-soft-dark my-0" :class="class_list" v-else style="min-height: 10px"></h2>
  </template>
</template>

<script>
export default {
  name: "skeleton",
  props: {
    custom_style: {
      type: String,
      default: ''
    },
    class_list: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    show_data_after_loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
  }
}
</script>

<style scoped>

</style>