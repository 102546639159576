<script>
import PerPageDropdown from "@/components/tables/components/PerPageDropdown.vue";

export default {
  name: "THeader",
  emits: ['selectAll', 'changePerPage', 'searchChange'],
  props: {
    headers: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    is_loading: {
      type: Boolean,
      required: true,
      default: () => false
    },
    all_rows_are_selected: {
      type: Boolean,
      required: false,
      default: () => false
    },
    per_page_location: {
      type: String,
      required: false,
      default: () => 'top'
    },
    per_page: {
      type: Number,
      required: true,
      default: () => 10
    },
    indexed: {
      type: Boolean,
      required: false,
      default: () => false
    },
    enable_global_search: {
      type: Boolean,
      required: false,
      default: () => false
    },
    is_searching_globally: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {PerPageDropdown},
  computed: {
    table_per_page: {
      get() {
        return this.per_page
      },
      set(value) {
        this.$emit('changePerPage', value)
      }
    },
  },
  methods: {
    selectAll() {
      this.$emit('selectAll')
    },
    onPerPageChange(event) {
      this.table_per_page = event
    },
    async searchChange(th) {
      // Make a copy of existing queries
      let existingQueries = {...this.$route.query};

      // Go through each header and update or delete query parameters
      this.myHeaders.forEach(header => {
        const searchText = (header.searchText || "").toString().trim();
        if (searchText) {
          // Update or add new query parameter
          existingQueries[header.field] = searchText;
        } else {
          // Remove query parameter if searchText is empty
          delete existingQueries[header.field];
        }
      });

      this.$emit('searchChange', th);

      // Update the route with the modified queries
      await this.$router.push({query: existingQueries});
    },
    checkSearchType(th, expected_type) {
      return th.searchType === expected_type
    },
    clearSearchedFields() {
      this.myHeaders.forEach(header => (header.searchText = ""));
    },
    sortBy(th) {
      if (th.sortable) {
        // Create a copy of the current route query
        let query = {...this.$route.query}

        if (query.order_by === th.field) {
          query.order_by = '-' + th.field
        } else {
          query.order_by = th.field
        }

        // Use the modified query object to update the route
        this.$router.push({query: {...query}})
      }
    },
    isSortedBy(th) {
      return this.$route.query.order_by ? this.$route.query.order_by.split('-').includes(th.field) : false
    },
  },
  data() {
    return {
      myHeaders: []
    }
  },
  mounted() {
    this.myHeaders = this.headers.map(i => {
      return {
        ...i,
        searchText: this.$route.query[i.field] || ''
      }
    })
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newQueries, oldQueries) {
        Object.entries(newQueries || {}).forEach(([key, value]) => {
          try {
            let header = this.myHeaders.find(i => i.field === key)
            header.searchText = value || ''
          } catch {
            console.log("not founf")
          }
        })
        Object.entries(oldQueries || {}).forEach(([key]) => {
          if (!Object.keys(newQueries).includes(key)) {
            try {
              let header = this.myHeaders.find(i => i.field === key)
              header.searchText = ''
            } catch {
              console.log("not founf")
            }
          }
        })
      },
    },
  }
}
</script>

<template>
  <thead>
  <tr class="bg-light">
    <th scope="col" style="width: 80px" v-if="selectable">
      <div class="form-check">
        <input
            :checked="all_rows_are_selected && !is_loading"
            :disabled="is_loading"
            class="form-check-input" type="checkbox" @click="selectAll()">
      </div>
    </th>
    <th v-if="indexed && !selectable" style="max-width: 30px" class="text-center">
      <span>#</span>
    </th>
    <th v-for="th in myHeaders" :key="th" class=""
        :class="[th.align ? 'text-' + th.align : '', th.sortable ? 'cursor-pointer': '']">
      <VTooltip v-if="th.sortable">
        <div @click="sortBy(th)">
          {{ th.label }}
          <img v-if="!isSortedBy(th)" class="sort-icon" src="@/assets/sort.svg"
               alt="Sort">

          <template v-else>
            <img v-if="this.$route.query.order_by.startsWith('-')" class="sort-icon" src="@/assets/sort_down.svg"
                 alt="Sort Down">
            <img v-else class="sort-icon" src="@/assets/sort_up.svg"
                 alt="Sort Up">
          </template>
        </div>
        <template v-slot:popper>Click to sort by <span class="text-uppercase">{{ th.label }}</span></template>
      </VTooltip>
      <span v-else>{{ th.label }}</span>
    </th>
  </tr>
  <tr class="align-middle" v-if="searchable">
    <th
        v-if="selectable"
        class="bg-transparent" scope="col" style="width: 80px">
      <PerPageDropdown
          v-if="per_page_location === 'top'"
          :per_page="per_page" @changePerPage="onPerPageChange"/>
    </th>
    <th v-if="indexed && !selectable" style="max-width: 30px" class="text-center"></th>

    <th v-for="th in myHeaders.filter(i => i.field !== 'actions')"
        :key="th" class="text-uppercase"
        :class="th.align ? 'text-' + th.align : ''">

      <template v-if="th.searchable !== false">
        <input @change="searchChange(th)"
               v-if="checkSearchType(th, 'date')"
               v-model="th.searchText" type="date"
               class="form-control form-control-sm"
               :disabled="is_searching_globally"
               :class="{
                 'text-muted': is_searching_globally
               }"
        />
        <select
            @change="searchChange(th)"
            v-else-if="th.searchType === 'select'"
            v-model="th.searchText"
            class="form-select form-select-sm"
            :disabled="is_searching_globally"
            :class="{
                 'text-muted': is_searching_globally
            }"
        >
          <option v-for="option in th.searchOptions" :key="option.value" :value="option.value">
            {{ option.label }}
          </option>
        </select>
        <input
            @input="searchChange(th)"
            v-model="th.searchText"
            v-else
            class="form-control w-75 m-auto form-control-sm"
            type="text"
            :disabled="is_searching_globally"
            :class="{
                 'text-muted': is_searching_globally
            }"
        />
      </template>
      <template v-else>
        <VTooltip>
          <input disabled style="cursor: not-allowed;"
                 class="form-control form-select-sm w-75 m-auto border-0"
                 type="text"
          />
          <template v-slot:popper>
            This field is not searchable
          </template>
        </VTooltip>
      </template>
    </th>
  </tr>
  </thead>
</template>

<style scoped>
.sort-icon {
  width: 15px; /* Adjust as needed */
  height: 12px;
}
</style>