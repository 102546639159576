<script>
import HeaderController from "@/components/tables/modals/HeaderController.vue";
import Statistics from "@/components/tables/modals/Statistics.vue";
import TableFunctions from "@/components/tables/functions.vue";
import _ from 'lodash';

export default {
  name: "TableHeader",
  emits: ['header_change'],
  props: {
    table_name: {
      type: String,
      default: () => "Table",
      required: false
    },
    headers: {
      type: Array,
      required: true
    },
    table_data: {
      type: Array,
      required: true
    },
    selected_data: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: false,
      default: () => ''
    },
    storageKey: {
      type: String,
      required: false,
      default: () => ''
    },
    excel_url: {
      type: String || null,
      required: false,
      default: () => null
    },
    showing_from: {
      type: Number,
      required: false,
      default: () => 0
    },
    showing_to: {
      type: Number,
      required: false,
      default: () => 0
    },
    data_count: {
      type: Number,
      required: false,
      default: () => 0
    },
    enable_global_search: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  components: {
    HeaderController,
    TableFunctions,
    Statistics
  },
  data() {
    return {
      showHeadersController: false,
      search: ''
    };
  },
  methods: {
    handleSearch: _.debounce(function () {
      if (!this.enable_global_search) return;
      const searchQuery = this.search.trim();

      // Get the current router query
      const currentQuery = {...this.$route.query};

      if (searchQuery.length) {
        currentQuery.search = searchQuery;
      } else {
        delete currentQuery.search;
      }

      this.$router.push({
        query: currentQuery,
      });
    }, 500), // Adjust the debounce delay as needed
  },
  mounted() {
    if (this.enable_global_search) {
      this.search = this.$route.query.search || '';
    }
  }
}
</script>

<template>
  <div class="card-header border-0 border-bottom-2 rounded-table-card">
    <div class="row gy-3 justify-content-between align-items-center">
      <div class="col-12 col-lg-4">
        <h5 class="card-title mb-3 fw-semibold">{{ table_name }}</h5>
        <div class="table-description-animation-wrapper">
          <Transition name="slide-up">
            <div class="bg-info" v-if="selected_data.length <= 0">
              <h6>
                Showing
                <span class="link-success fw-semibold">{{ showing_from }}</span>
                to
                <span class="link-success fw-semibold">{{ showing_to }}</span>
                of
                <span class="link-success fw-semibold">{{ data_count ? data_count : 0 }}</span>
                entries
              </h6>
            </div>
            <div v-else-if="selected_data.length > 0">
              <h6>
                Selected
                <span class="text-success fw-semibold">
                  {{ selected_data && selected_data.length }}
                </span>
                rows
              </h6>
            </div>
          </Transition>
        </div>
      </div>
      <div class="col-12 col-lg-8 pe-0">
        <div class="d-flex justify-content-start justify-content-lg-end align-items-center flex-wrap">
          <div v-if="enable_global_search" class="search-box me-3">
            <input v-model="search" @input="handleSearch" type="text" class="form-control"
                   placeholder="Search in table...">
            <i class="ri-search-line search-icon"></i>
          </div>
          <TableFunctions
              :table_name="table_name"
              :table_headers="headers"
              :table_data="table_data"
              :selected_data="selected_data"
              :excel_url="excel_url"
              :url="url"
          >
            <template v-slot:functions="selected_data">
              <slot name="functions" :rows="selected_data.rows"></slot>
            </template>
          </TableFunctions>
          <div class="me-3">
            <slot name="top-right"></slot>
          </div>
          <Statistics :headers="headers"/>
          <HeaderController
              @header_change="this.$emit('header_change')"
              :storageKey="storageKey" :headers="headers"/>
        </div>
      </div>
    </div>
    <div>
      <slot name="header_div"></slot>
    </div>
  </div>
</template>

<style scoped>

.table-description-animation-wrapper {
  position: relative;
}

.table-description-animation-wrapper h6 {
  position: absolute;
  top: -10px
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>