<script>
import skeleton from "@/components/custom/skeleton.vue";
import NoData from "@/components/custom/NoData.vue";

export default {
  name: "TBody",
  emits: ['selectRow', 'rowClick'],
  components: {NoData, skeleton},
  props: {
    is_loading: {
      type: Boolean,
      required: true,
      default: () => false
    },
    is_loading_more: {
      type: Boolean,
      required: true,
      default: () => false
    },
    headers: {
      type: Array,
      required: true
    },
    table_data: {
      type: Array,
      required: true
    },
    selected_data: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showing_from: {
      type: Number,
      required: false,
      default: () => 0
    },
    indexed: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  methods: {
    selectRow(row) {
      let multiple_rows = this.table_data.slice(this.table_data.indexOf(this.selected_data[0]), this.table_data.indexOf(row) + 1)
      this.$emit('selectRow', row, this.shiftKeyPressed
          ? multiple_rows.length > 0 ? multiple_rows : undefined
          : undefined
      )
    },
    onRowClick(row) {
      this.$emit('rowClick', row)
    },

    handleKeydown(event) {
      if (event.key === 'Shift') {
        this.shiftKeyPressed = true
      }
    },
    handleKeyup(event) {
      if (event.key === 'Shift') {
        this.shiftKeyPressed = false
      }
    }
  },
  data() {
    return {
      potential_rows_count: 0,
      shiftKeyPressed: false
    }
  },
  watch: {
    table_data: {
      handler() {
        this.potential_rows_count = this.table_data.length === 0 ? 4 : this.table_data.length + 2;
      },
      immediate: true,
    }
  },
  created() {
    document.addEventListener('keydown', this.handleKeydown)
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
    document.removeEventListener('keyup', this.handleKeyup)
  },
}
</script>

<template>
  <tbody v-if="table_data.length > 0 && !is_loading">

  <tr v-for="(data, index) in table_data"
      :key="`table_data_` + data.id"
      :class="{
          'bg-light' : selected_data.includes(data)
        }"
      @click.stop="onRowClick(data)"
  >
    <th scope="col" style="width: 50px" v-if="selectable === true"
        :class="{
          'border-0 border-start border-3 border-primary' : selected_data.includes(data)
        }">
      <div class="d-flex gap-3">
        <span>{{ showing_from + index }}</span>
        <div class="form-check">
          <input class="form-check-input"
                 :checked="selected_data.map(i => i.id).includes(data.id)"
                 type="checkbox" @click.stop="selectRow(data, index)"/>
        </div>
      </div>
    </th>
    <th v-if="indexed && !selectable" class="text-center" style="max-width: 30px">
      <span>{{ showing_from + index }}</span>
    </th>
    <template v-for="(td, cell_index) in headers" :key="`${index}_${td}_${cell_index}`">
      <slot :name="`${td.field}_td`" :row="data" :index="index"
      >
        <td :ref="`${td.field}_td`"
            :class="td.align === undefined ? '' : 'text-' + td.align"
            :style="{maxWidth: td.maxWidth || 'auto'}"
        >
          <slot :name="td.field" :row="data" :index="index"
          >
            {{ data[td.field] }}
          </slot>
        </td>
      </slot>
    </template>
  </tr>
  <template v-if="is_loading_more">
    <tr>
      <td :colspan="headers.length + (selectable ? 1: 0)"
          class="text-center py-3">
        <div class="d-flex align-items-center justify-content-center gap-3 w-100">
          <b-spinner style="width: 25px; height: 25px" variant="primary"></b-spinner>
          <span>Loading...</span>
        </div>
      </td>
    </tr>
  </template>

  </tbody>

  <tbody v-else-if="table_data && is_loading">
  <tr v-for="i in potential_rows_count" :key="`potential_rows_count_${i}`">
    <th scope="col" style="width: 50px" v-if="selectable === true">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" disabled/>
      </div>
    </th>
    <td v-for="td in headers.length + (selectable ? 0 : 1)" :key="`table_loading_row_${td}`">
      <skeleton style="height: 12px"/>
    </td>
  </tr>
  </tbody>

  <tbody v-else-if="table_data.length === 0 && !is_loading">
  <tr class="text-center border-white">
    <td :colspan="headers.length +1 ">
      <NoData/>
    </td>
  </tr>
  </tbody>
</template>

<style scoped>

</style>