<script>
export default {
  name: "HeaderController",
  emits: ['header_change'],
  props: {
    headers: {
      type: Array,
      required: true
    },
    storageKey: {
      type: String,
      required: false,
      default: () => ''
    },
  },
  methods: {
    changeVisibility(header, visibility) {
      header.visible = visibility;

      // Get the current stored headers from localStorage
      let storedHeaders = JSON.parse(localStorage.getItem(this.storageKey)) || [];

      if (visibility) {
        if (!storedHeaders.includes(header.field)) {
          storedHeaders.push(header.field);
        }
      } else {
        storedHeaders = storedHeaders.filter(field => field !== header.field);
      }

      localStorage.setItem(this.storageKey, JSON.stringify(storedHeaders));

      this.$emit('header_change');
    }
  },
  mounted() {
    // Get the current stored headers from localStorage
    let storedHeaders = JSON.parse(localStorage.getItem(this.storageKey)) || [];

    if (storedHeaders) {
      this.headers.forEach(header => {
        header.visible = storedHeaders.includes(header.field);
      })
    }
  }
}
</script>

<template>
  <b-button
      v-b-tooltip.hover title="Control Table Headers"
      variant="outline-primary"
      data-bs-toggle="modal"
      data-bs-target="#TableHeaderController"
      class="btn-icon waves-effect me-3">
    <i class="ri-menu-2-line"></i>
  </b-button>

  <div id="TableHeaderController" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-dialog-right">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Control Table Headers
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3">

          <div class="d-flex flex-wrap gap-4 py-2 mb-4">
            <div v-for="i in headers.filter((h) => h.label !== 'actions')" :key="i">
              <div class="form-check form-switch">
                <input class="form-check-input" role="switch" :id="i.label + 'Check'" type="checkbox"
                       v-if="i.visible === true || i.visible === undefined" @click="changeVisibility(i, false)"
                       checked/>
                <input class="form-check-input" role="switch" :id="i.label + 'Check'" type="checkbox"
                       v-if="i.visible === false" @click="changeVisibility(i, true)"/>
                <label class="form-check-label" :for="i.label + 'Check'">
                  {{ i.label }}
                </label>
              </div>
            </div>
          </div>

          <b-row>
            <b-col :sm="12">
              <h6 class="text-muted fs-14 mb-0">
                <i class="mdi mdi-information-outline align-middle fs-15"></i>
                Toggle table headers:
                <span class="text-success">ON</span> for visible,
                <span class="text-danger">OFF</span> for hidden.
              </h6>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>